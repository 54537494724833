<template>
  <!-- <v-app-bar
    id="app-bar"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue darken-3"
    dark
  > -->
  <v-app-bar
    id="app-bar"
    app
    color="grey lighten-3"
  >
    <v-btn class="mr-3" elevation="1" icon fab small @click="setDrawer(!drawer)">
      <v-icon v-if="drawer">mdi-dots-vertical</v-icon>
      <v-icon v-else>mdi-view-quilt</v-icon>
    </v-btn>

    <v-toolbar-title :style="$vuetify.breakpoint.xsOnly ? 'width: 100%': 'width: 120px'" class="ml-0 pl-4" align="center">
      <v-img
        width="100"
        :src="require('@/assets/logo_gavcom_100_60.png')"
      ></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-autocomplete ref="autocomplete"
     v-model="select"
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      clearable
      hide-no-data
      hide-selected
      hide-details
      color="grey"
      label="Búsqueda..."
      class="hidden-xs-only pt-2"
      item-text="text"
      item-value="text"
      append-icon="" 
      prepend-inner-icon="mdi-magnify"
    >
      <template v-slot:item="{ item }">
        <v-list-item @click="selected(item.to)">
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-spacer></v-spacer>

    <v-btn v-if="isAdmin" class="ml-2" min-width="0" icon @click="setAdminDrawer(!adminDrawer)">
      <v-icon large>mdi-account-cog</v-icon>
    </v-btn>

    <!-- <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" icon v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <!-- <v-btn class="ml-2" min-width="0" icon @click="!showLockDialog">
      <v-icon large>mdi-lock-clock</v-icon>
    </v-btn> -->

    <!-- <base-form-lock></base-form-lock> -->

    <v-btn class="ml-2" min-width="0" icon large>
      <base-profile-menu item/>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "GavcomCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    },
    
  },

  data() {
    return {
      select: null,
      search: null,
      items: [],
      loading: false,
      showLockDialog: false,

      // notifications: [
      //   "Mike John Responded to your email",
      //   "You have 5 new tasks",
      //   "You're now friends with Andrew",
      //   "Another Notification",
      //   "Another one"
      // ]
    }
  },

  computed: {
    ...mapState(["drawer", "availableMenu", "adminDrawer"]),
    ...mapGetters(['isAdmin']),
  },

  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setAdminDrawer: "SET_ADMIN_DRAWER",
    }),

    querySelections (v) {
      this.loading = true
      this.items = this.availableMenu.filter(e => {
        return (e.text || '').toLowerCase().indexOf((v.text || '').toLowerCase()) > -1
      })
      // console.log('querySelections', this.items)
      this.loading = false
    },

    selected(to) {
      this.$refs.autocomplete.isMenuActive = false
      this.select = null
      this.search = null
      this.items = []
      if (this.$route.name !== to.name)
      {
        this.$router.push(to);
      }
    }
  }
};
</script>